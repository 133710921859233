<template>
    <div class="content-level" style="height:100%">
        <div class="call-level-style">
            <callSelect
                :doptions="getDepartments"
                :call-search-change="false"
                :options-member="getStaff"
                :call-type="callType"
                :call-duration="getCallDuration"
                :call-sort="getOrderBy"
                @departmentIDArray="getDepartment"
                @departmenber="getMenberId"
                @changeTime="getTimeChange"
                @callTypeChange="getCallType"
                @callDurationChange="getCallDurationChange"
                @callSortChange="getCallSortChange"
                @changeDateTime="changeDateTime"
            />
            <div class="call-table">
                <div class="call-record-selectStyle-down-all">
                    <div style="width: 40%;">
                        <!-- <div class="call-search" v-if="callSearchChange==false">
							<el-input v-model="input" placeholder="输入客户姓名/手机号查询" size="mini"></el-input>
							<img src="../../../assets/common/search.png" width="20" height="20" class="call-search-icon"
								@click="callSearch()">
                        </div>-->
                    </div>
                    <div class="call-search-time">
                        <div style="display: flex;align-items: center;">
                            <div style="margin-right: 10px;font-size: 15px;">时间</div>
                            <div>
                                <el-radio-group
                                    v-model="timeTabs"
                                    size="small"
                                    @change="changeTime"
                                >
                                    <el-radio-button label="1">今日</el-radio-button>
                                    <el-radio-button label="2">昨日</el-radio-button>
                                    <el-radio-button label="3">最近一周</el-radio-button>
                                    <el-radio-button label="4">最近一月</el-radio-button>
                                </el-radio-group>
                            </div>
                        </div>
                        <div style="margin-left: 20px;">
                            <el-date-picker
                                v-model="valuetime"
                                size="small"
                                type="datetimerange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                value-format="timestamp"
                                :default-time="['00:00:00', '23:59:59']"
                                @change="time"
                            />
                        </div>
                    </div>
                </div>
                <!-- overflow-y:scroll;height: 600px; -->
                <div style="margin: 0px 20px 0px 20px;">
                    <!-- v-loading="loading" v-infinite-scroll="loadMore" infinite-scroll-delay="300" infinite-scroll-disabled="disabled" infinite-scroll-distance="30" -->
                    <el-table
                        v-loading="loading"
                        :data="levelList"
                        style="width: 100%;overflow:auto"
                        :height="tableHeight"
                        border
                    >
                        <el-table-column label="排名" width="90" align="center" type="index">
                            <!-- <template v-slot="scope">
								<span>{{scope.row.memberLevel}}</span>
                            </template>-->
                        </el-table-column>
                        <el-table-column prop="callerName" label="员工" width="180" align="center" />
                        <el-table-column prop="callCount" label="呼叫次数/次" align="center" />
                        <el-table-column prop="answerCount" label="接通次数/次" align="center" />
                        <el-table-column prop="greater30sCount" label="30-59秒内次数" align="center" />
                        <el-table-column prop="greater60sCount" label="60s以上次数" align="center" />
                        <el-table-column prop="durationTotal" label="累计通话时长/分" align="center" />
                        <el-table-column prop="durationAverage" label="平均通话时长/秒" align="center" />
                        <el-table-column prop="answerRate" label="接通率" align="center" />
                    </el-table>
                    <!-- <p v-if="loadingIn">加载中...</p>
                    <p v-if="noMore">没有更多了</p>-->
                </div>
                <div class="call-page">
                    <div>
                        <!-- <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
							:current-page="currentPage" :page-size="pageSizeNo"
							layout="total, sizes, prev, pager, next, jumper" :total="pageTotal">
                        </el-pagination>-->
                        <el-pagination
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="pageTotal"
                            :page-size="pageSizeNo"
                            @current-change="handleCurrentChange"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import callSelect from '../../../components/callSelect.vue';
import { callTop } from '../../../api/callPhone_Infor.js';
// import {
// 	doptions,
// 	list
// } from '../../../assets/js/falseData.js'
import { departments, staff } from '../../../api/public_content.js';
export default {
    data() {
        return {
            // doptions,
            // list,
            memberLevel: '1',
            statusTime: {
                startTime: '',
                endTime: '',
            },
            timeTabs: 1, //时间段
            valuetime: '', //选择转为时间值
            loading: false,
            loadingIn: false,
            currentPage: 1,
            pageSizeNo: 0,
            pageTotal: 0,
            levelList: [],
            getDepartments: [],
            getStaff: [],
            getDepartmentId: '',
            callTopStatus: {
                keyword: '',
                departmentId: '',
                staffId: '',
                type: '',
                duration: '',
                orderBy: '',
                startTime: '',
                endTime: '',
                pageNo: 1,
                pageSize: 10,
            },
            text: '',
            callType: [
                {
                    name: '全部',
                    id: 0,
                },
                {
                    name: '呼入',
                    id: 1,
                },
                {
                    name: '呼出',
                    id: 2,
                },
            ],
            getCallDuration: [
                {
                    name: '全部',
                    id: 0,
                },
                {
                    name: '1到30秒',
                    id: 1,
                },
                {
                    name: '31到60秒',
                    id: 2,
                },
                {
                    name: '1分钟到3分钟',
                    id: 3,
                },
                {
                    name: '3分钟以上',
                    id: 4,
                },
            ],
            getOrderBy: [
                {
                    name: '默认排序',
                    id: 0,
                },
                {
                    name: '接通次数排序',
                    id: 1,
                },
                {
                    name: '通话时长排序',
                    id: 2,
                },
                {
                    name: '平均呼叫时长排',
                    id: 3,
                },
                {
                    name: '接通率排序',
                    id: 4,
                },
            ],
            windowinnerHeight: 0,
        };
    },
    created() {
        this.windowinnerHeight = window.innerHeight;
        window.addEventListener('resize', this.getHeight);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.getHeight);
    },
    methods: {
        getHeight() {
            this.windowinnerHeight = window.innerHeight;
        },
        changeDateTime(val) {
            this.callTopStatus.startTime = val.startTime;
            this.callTopStatus.endTime = val.endTime;
            this.loading = true;
            this.getCallTop();
        },

        time(val) {
            if (val == null) {
                this.timeTabs = 0;
                this.valuetime = '';
                // this.handleCurrentChange(1);
            } else {
                this.timeTabs = '';
                this.valuetime = val;
                // this.handleCurrentChange(1);
            }
            // this.statusTime.startTime = this.valuetime[0] / 1000;
            // this.statusTime.endTime = this.valuetime[1] / 1000;
            this.callTopStatus.startTime = this.valuetime[0] / 1000;
            this.callTopStatus.endTime = this.valuetime[1] / 1000;
            this.loading = true;
            this.getCallTop();
            // this.$emit('changeDateTime', this.statusTime, this.valuetime, this.timeTabs)
        },

        changeTime(le) {
            // console.log(le, 'le')
            // console.log(this.timeTabs, 'timetab')
            const start = new Date(new Date(new Date().toLocaleDateString()));
            const end = new Date(
                new Date(new Date().toLocaleDateString()).getTime() +
                    24 * 60 * 60 * 1000 -
                    1
            );
            // 获取昨天
            let zuo = start.getTime() - 3600 * 1000 * 24 * 1;
            // 获取周
            let zhou = start.getTime() - 3600 * 1000 * 24 * 6;
            // 获取月
            let yue = start.getTime() - 3600 * 1000 * 24 * 30;

            if (this.timeTabs == 0) this.valuetime = []; // 全部
            if (this.timeTabs == 1) this.valuetime = [start.getTime(), end]; //今日
            if (this.timeTabs == 2) this.valuetime = [zuo, start.getTime() - 1]; // 昨日
            if (this.timeTabs == 3) this.valuetime = [zhou, end]; //最近一周
            if (this.timeTabs == 4) this.valuetime = [yue, end]; //最近一月
            this.statusTime.startTime = this.valuetime[0] / 1000;
            this.statusTime.endTime = this.valuetime[1] / 1000;
            // this.$emit('changeTime', this.statusTime, this.valuetime, this.timeTabs)
            // console.log(this.statusTime,'statusTime')
            this.getTimeChange(this.statusTime, this.valuetime, this.timeTabs);
        },

        handleSizeChange(val) {
            this.pageSizeNo = val;
            this.callTopStatus.pageSize = this.pageSizeNo;
            this.loading = true;
            console.log(`每页 ${val} 条`);
            this.getCallTop();
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.callTopStatus.pageNo = this.currentPage;
            this.loading = true;
            console.log(`当前页: ${val}`);
            this.getCallTop();
        },
        getCallTop() {
            // if(this.loadingIn==false){
            callTop(this.callTopStatus).then((res) => {
                this.levelList = res.data.list;
                this.pageTotal = res.data.total;
                console.log(res.data, 'reseq');
                setTimeout(() => {
                    this.loading = false;
                }, 500);
                // this.loadingIn=true
            });
            // this.loadingIn=true
            // }
        },

        getDepartment(departmentId) {
            departments({}).then((res) => {
                this.getDepartments = res.data.list;
                console.log(res, 'department');
            });
            // this.getDepartmentId = val
            this.currentPage = 1;
            this.callTopStatus.pageNo = 1;
            this.callTopStatus.staffId = '';
            this.callTopStatus.type = '';
            this.callTopStatus.duration = '';
            this.callTopStatus.departmentId = departmentId;
            console.log(departmentId, 'departmentId');
            staff({
                departmentId: departmentId,
            }).then((res) => {
                this.getStaff = res.data.list;
                console.log(res, 'staff');
            });
            this.loading = true;
            this.getCallTop();
        },

        getMenberId(val) {
            this.loading = true;
            this.currentPage = 1;
            this.callTopStatus.pageNo = 1;
            this.callTopStatus.type = '';
            this.callTopStatus.duration = '';
            this.callTopStatus.staffId = val;
            this.getCallTop();
        },

        getCallType(valType) {
            this.loading = true;
            this.currentPage = 1;
            this.callTopStatus.pageNo = 1;
            this.callTopStatus.duration = '';
            this.callTopStatus.type = valType;
            this.getCallTop();
        },

        getCallDurationChange(Duration) {
            this.loading = true;
            this.currentPage = 1;
            this.callTopStatus.pageNo = 1;
            this.callTopStatus.duration = Duration;
            this.getCallTop();
        },

        getCallSortChange(Sort) {
            this.loading = true;
            this.currentPage = 1;
            this.callTopStatus.pageNo = 1;
            this.callTopStatus.orderBy = Sort;
            console.log(Sort, 'sost');
            this.getCallTop();
        },

        getTimeChange(statusTime, valuetime, timeTab) {
            // console.log(statusTime,'statusTime')
            // console.log(valuetime,'valuetime')
            // console.log(timeTab, 'TIMETAB')
            // console.log(valuetime[0] / 1000, 'valuetime0')
            this.currentPage = 1;
            this.callTopStatus.pageNo = 1;
            this.callTopStatus.startTime = valuetime[0] / 1000;
            this.callTopStatus.endTime = valuetime[1] / 1000;
            this.loading = true;
            // this.callTopStatus.startTime=valuetime.startTime
            // this.callTopStatus.endTime=statusTime.endTime
            this.getCallTop();
        },
        loadMore() {
            this.loadingIn = true;
            setTimeout(() => {
                this.loading = true;
                this.callTopStatus.pageSize += 1;
                this.getCallTop();
                this.loadingIn = false;
            }, 2000);
            console.log(this.loadingIn, 'loding');
            // this.loadingIn=false
            console.log(1);
        },
    },
    mounted() {
        // this.getCallTop()
        // this.load()
        const TodaystartTime = new Date(
            new Date(new Date().toLocaleDateString())
        );
        const TodayendTime = new Date(
            new Date(new Date().toLocaleDateString()).getTime() +
                24 * 60 * 60 * 1000 -
                1
        );
        this.callTopStatus.startTime = TodaystartTime.getTime() / 1000;
        this.callTopStatus.endTime = TodayendTime / 1000;
        console.log(TodayendTime / 1000, 'TodayendTime/1000');

        this.getDepartment();
    },
    components: {
        callSelect,
    },
    computed: {
        // noMore() {
        // 	return this.callTopStatus.pageSize >= this.pageTotal
        // },
        // disabled() {
        // 	return this.loadingIn || this.noMore

        // }
        tableHeight() {
            return this.windowinnerHeight - 310 + 'px';
        },
    },
    watch: {},
};
</script>

<style lang="less" scoped="scoped">
.content-level {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .call-level-style {
        height: 100%;
        margin: 0 15px 0 15px;
    }

    .call-table {
        margin-top: 10px;
        overflow-y: scroll;
        // height: 700px;
        border-radius: 10px;
        background-color: #ffffff;
    }

    .call-page {
        width: 100%;
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        // border: 1px solid black;
        div {
            margin-right: 30px;
            margin-bottom: 10px;
        }
    }
}

.call-record-selectStyle-down-all {
    // margin-top: 20px;

    margin: 20px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .call-search {
        border-radius: 3px;
        box-shadow: 0 0px 2px #999999;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 240px;
        height: 28px;
        cursor: pointer;

        .call-search-icon {
            margin: 0 5px 0 5px;
        }
    }

    .call-search-time {
        // width: 50%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
    }
}
</style>
