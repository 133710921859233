import request from './request.js';
import public_request from './public_request';

//通话记录
export function callRecord(data) {
    return request({
        url: '/data-management/call-records',
        methods: 'get',
        params: public_request(data),
    });
}

// 通话排名
export function callTop(data) {
    return request({
        url: '/data-management/call-tops',
        methods: 'get',
        params: public_request(data),
    });
}

// 通话分析
export function callChart(data) {
    return request({
        url: '/data-management/call-charts',
        methods: 'GET',
        params: public_request(data),
    });
}
