<template>
    <div class="callrecord">
        <div class="call-record-selectStyle-up-all">
            <div v-if="development" class="call-record-selectStyle">
                <div class="call-item">
                    <span class="span-ts">部门：</span>
                    <div class="select-width">
                        <el-cascader
                            ref="cascaderHandle"
                            :key="cascaderKey"
                            v-model="ccc"
                            :options="doptions"
                            size="mini"
                            :props="{expandTrigger: 'hover', checkStrictly: true,
                       label: 'name', value: 'id'}"
                            placeholder="选择部门"
                            clearable
                            @change="casc"
                        />
                    </div>
                </div>
                <div class="call-item mar-left">
                    <span class="span-ts">员工：</span>
                    <div class="select-width">
                        <el-select
                            v-model="cc"
                            placeholder="选择个人"
                            size="mini"
                            clearable
                            @change="getDepartmentMemberChange"
                        >
                            <el-option
                                v-for="item in optionsMember"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                            />
                        </el-select>
                    </div>
                </div>
                <div class="call-item mar-left">
                    <span class="span-ts">通话类型：</span>
                    <div class="select-width">
                        <el-select
                            v-model="ct"
                            placeholder="请选择"
                            size="mini"
                            clearable
                            @change="callTypeChange"
                        >
                            <el-option
                                v-for="item in callType"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                            />
                        </el-select>
                    </div>
                </div>
                <div class="call-item mar-left">
                    <span class="span-ts">通话时长：</span>
                    <div class="select-width">
                        <el-select
                            v-model="cg"
                            placeholder="请选择"
                            size="mini"
                            clearable
                            @change="callDurationChange"
                        >
                            <el-option
                                v-for="item in callDuration"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                            />
                        </el-select>
                    </div>
                </div>
            </div>

            <div v-if="sortChange" class="default-selectLevel">
                <el-select v-model="vad" placeholder="请选择" size="mini" @change="callSortChange">
                    <el-option
                        v-for="item in callSort"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    />
                </el-select>
            </div>
        </div>
        <!-- 	<div class="call-record-selectStyle-down-all">
			<div style="width: 40%;">
				<div class="call-search" v-if="callSearchChange">
					<el-input v-model="input" placeholder="输入客户姓名/手机号查询" size="mini"></el-input>
					<img src="../assets/common/search.png" width="20" height="20" class="call-search-icon"
						@click="callSearch()">
				</div>
			</div>
			<div class="call-search-time">
				<div style="display: flex;align-items: center;">
					<div style="margin-right: 10px;font-size: 15px;">时间</div>
					<div>
						<el-radio-group v-model="timeTabs" size="small" @change="changeTime">
							<el-radio-button label="1">今日</el-radio-button>
							<el-radio-button label="2">昨日</el-radio-button>
							<el-radio-button label="3">最近一周</el-radio-button>
						</el-radio-group>
					</div>
				</div>
				<div style="margin-left: 20px;">
					<el-date-picker size="small" v-model="valuetime" type="datetimerange" range-separator="至"
						start-placeholder="开始日期" end-placeholder="结束日期" value-format="timestamp"
						:default-time="['00:00:00', '23:59:59']" @change="time"></el-date-picker>
				</div>
			</div>
        </div>-->
    </div>
</template>

<script>
export default {
    data() {
        return {
            // departmentValue: '全部',
            parterValue: ['全部'],
            vad: '默认排序',
            ccc: ['我的'],
            cc: '',
            ct: '',
            cg: '',
            value: [],
            input: '',
            timeTabs: 1, //时间段
            valuetime: '', //选择转为时间值
            statusTime: {
                startTime: '',
                endTime: '',
            },
            cascaderKey: 0,
            optionsMembers: [],
        };
    },
    props: {
        doptions: {
            type: Array,
            default: () => [],
        },
        optionsMember: {
            type: Array,
            default: () => [],
        },
        development: {
            type: Boolean,
            default: () => true,
        },
        sortChange: {
            type: Boolean,
            default: () => true,
        },
        callSearchChange: {
            type: Boolean,
            default: () => true,
        },
        callType: {
            type: Array,
            default: () => [],
        },
        callDuration: {
            type: Array,
            default: () => [],
        },
        callSort: {
            type: Array,
            default: () => [],
        },
    },
    methods: {
        casc(val) {
            console.log(val);
            this.cc = '';
            this.ct = '';
            this.cg = '';
            ++this.cascaderKey;
            this.$refs.cascaderHandle.dropDownVisible = false;
            this.$emit('departmentIDArray', val[val.length - 1]);
        },

        getDepartmentMemberChange(valId) {
            this.ct = '';
            this.cg = '';
            this.$emit('departmenber', this.cc);
        },

        callTypeChange(valType) {
            this.cg = '';
            this.$emit('callTypeChange', this.ct);
        },

        callDurationChange(callDurationType) {
            this.$emit('callDurationChange', this.cg);
        },

        callSortChange(sort) {
            this.$emit('callSortChange', sort);
        },

        changeTime(le) {
            const start = new Date(new Date(new Date().toLocaleDateString()));
            const end = new Date(
                new Date(new Date().toLocaleDateString()).getTime() +
                    24 * 60 * 60 * 1000 -
                    1
            );
            // 获取昨天
            let zuo = start.getTime() - 3600 * 1000 * 24 * 1;
            // 获取周
            let zhou = start.getTime() - 3600 * 1000 * 24 * 6;

            if (this.timeTabs == 0) this.valuetime = []; // 全部
            if (this.timeTabs == 1) this.valuetime = [start.getTime(), end]; //今日
            if (this.timeTabs == 2) this.valuetime = [zuo, start.getTime() - 1]; // 昨日
            if (this.timeTabs == 3) this.valuetime = [zhou, end]; //最近一周
            this.statusTime.startTime = this.valuetime[0] / 1000;
            this.statusTime.endTime = this.valuetime[1] / 1000;
            this.$emit(
                'changeTime',
                this.statusTime,
                this.valuetime,
                this.timeTabs
            );
        },
        time(val) {
            if (val == null) {
                this.timeTabs = 0;
                this.valuetime = '';
                // this.handleCurrentChange(1);
            } else {
                this.timeTabs = '';
                this.valuetime = val;
                // this.handleCurrentChange(1);
            }
            this.statusTime.startTime = this.valuetime[0] / 1000;
            this.statusTime.endTime = this.valuetime[1] / 1000;
            this.$emit(
                'changeDateTime',
                this.statusTime,
                this.valuetime,
                this.timeTabs
            );
        },
        callSearch() {
            this.$emit('inputkeyword', this.input);
            // this.valuetime
        },
    },
};
</script>

<style lang="less" scoped="scoped">
.callrecord {
    // border: 1px solid black;
    margin-top: 10px;
    border-radius: 10px;
    background-color: #ffffff;
    .call-record-selectStyle-up-all {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        // align-items: flex-end;
        align-items: center;

        .call-record-selectStyle {
            display: flex;
            // width: 50%;
            flex-direction: row;
            margin-top: 10px;
            margin-bottom: 10px;
            margin-left: 10px;
            // border: 1px solid black;

            .call-item {
                display: flex;
                flex-direction: row;
            }

            .mar-left {
                margin-left: 10px;
            }

            .span-ts {
                display: block;
                margin-top: 1px;
                font-size: 14px;
                line-height: 26px;
            }
        }

        .default-selectLevel {
            width: 150px;
            height: 28px;
            margin-right: 30px;
        }
    }

    .call-record-selectStyle-down-all {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .call-search {
            border-radius: 3px;
            box-shadow: 0 0px 2px #999999;
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 240px;
            height: 28px;
            cursor: pointer;

            .call-search-icon {
                margin: 0 5px 0 5px;
            }
        }

        .call-search-time {
            // width: 50%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
        }
    }

    .select-width {
        width: 180px;
        height: 27px;
    }
}
</style>
